import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Input } from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";
import {useFormik} from "formik";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import "./FTPSettings.css";

import {
    deleteSettings, getFTPSettings,
    saveSettings,
    testExistingConnection,
    testNewConnection,
    updateSettings,
} from "../../../actions/OnBoardingActions";
import * as Yup from "yup";
import {UDX} from "../../../Utils/UDX";
import {FTP_STRING} from "../../../api/constants";

const FTPSettings = (props) => {
    const [pageLoader, setPageLoader] = useState(false);
    const [visible, setVisible] = useState(props.visible);
    const ftpProtocols = ["sftp","ftps"];
    const transferModes = [
        {text: "Passive", id: true},
        {text: "Active", id: false}
    ];

    const [disableSubmit, setDisableSubmit] = useState(false);
    const [ftpProtocol, setFtpProtocol] = useState("ftps");


    const ftpFormik = useFormik({
        initialValues: {
            connection_name: "",
            host: "",
            passive_transfer_mode: true,
            user_name: "",
            password: "",
            port: "",
            starting_path: "",
            transfer_protocol: "ftps",
        },
        validationSchema: Yup.object().shape({
            passive_transfer_mode: Yup.boolean().required("Transfer Mode is required"),
            //connection_name: Yup.string().required("Connection name is required"),
            connection_name: Yup.string().required("Connection name is required")
                .test('no-apostrophes', 'The string must not contain apostrophes', (value) => {
                    return value && !value.includes("'");
                }),
            user_name: Yup.string().required("User name is required"),
            host: Yup.string().required("An FTP Host is required")
                .test('valid-host-name', 'Must be a valid domain name (semcasting.com) or IP Address (127.0.0.1)', (value)=>{
                    return  (UDX.regex.domain.test(value) || UDX.regex.ipv4.test(value))
                }),
            //port:Yup.string.min(1, "Must be between 1 - 65535").max(65535,"Must be between 1 - 65535"),
            port: Yup.number().nullable()
                .min(1, 'Must be between 1 - 65535')
                .max(65535, 'Must be between 1 - 65535'),
            password: Yup.string().required("Password is required"),
        }),
        onSubmit: () => {

        },
    });

    const ftpProtocolChange = (e) => {
        console.log ("ftpProtocolChangeE=", e);
        setFtpProtocol(e.value);
    }
    const testFTPSettings = async (editDialogSubmit= false) => {
        const hostName = getHostName(ftpFormik.values.host);
        const payload = {...ftpFormik.values, host: hostName, transfer_protocol: ftpProtocol};
        console.log("testFTPSettingsPayload=", payload);
        setPageLoader(true);

        if (props.mode ==='EDIT') {
            await testExistingConnection(payload,FTP_STRING,editDialogSubmit).then((res) => {
                // console.log("test ftp settings response:", res);
                setPageLoader(false);
                return res;
            });
        } else {
            await testNewConnection(payload,FTP_STRING,editDialogSubmit).then((res) => {
                // console.log("test ftp settings response:", res);
                setPageLoader(false);
                return res;
            });
        }
    };

    const getHostName = (link) => {
        let url;
        try {
            const hostName = new URL(link);
            url = hostName.host;
        } catch (_) {
            url = link;
        }
        return url;
    };

    const saveFTPSettings = async () => {
        const payload = {
            connection_name: ftpFormik.values.connection_name,
            host: ftpFormik.values.host,
            passive_transfer_mode: ftpFormik.values.passive_transfer_mode,
            password: ftpFormik.values.password,
            port: ftpFormik.values.port,
            starting_path: ftpFormik.values.starting_path,
            transfer_protocol: ftpProtocol,
            user_name: ftpFormik.values.user_name
        }

        console.log("saving FTP settings payload:", payload);


        if (props.mode === 'EDIT') {
            await updateSettings(payload, props.ftpSettings.id, ftpFormik.values.connection_name).then(async (res) => {
                await props.getAllSavedSettings();
                setPageLoader(false);
                ftpFormik.resetForm();


                await props.refreshTable().then(() => {
                    setVisible(!visible);
                    props.setFtpVisible(false);
                })
            })
        } else {
            await saveSettings(payload, ftpFormik.values.connection_name).then(async (res) => {
                await props.getAllSavedSettings();
                setPageLoader(false);
                ftpFormik.resetForm();


                await props.refreshTable().then(() => {
                    setVisible(!visible);
                    props.setFtpVisible(false);
                })
            });
        }
    };

    const removeFtpSettings = async () => {
        setPageLoader(true);
        deleteSettings(props.ftpSettings.id, ftpFormik.values.connection_name).then(async (res) => {
            // setExistingFTPSettingId(null);
            setPageLoader(false);
            await props.getAllSavedSettings();
            ftpFormik.resetForm();

            await props.refreshTable().then(() => {
                setVisible(!visible);
                props.setFtpVisible(false);
            })

            setVisible(!visible);
            props.setFtpVisible(false);
        });
    };

    const determineTransferMode = () => {
        if (props.mode === 'EDIT') {
            if (props.ftpSettings.passive_transfer_mode) return {text:"Passive", id: true};
            return {text:"Active", id: false};
        } else {
            return {text:"Passive", id: true};
        }

    };

    useEffect(() => {
        if (props.mode === 'EDIT') {
            ftpFormik.values.connection_name = props.ftpSettings.connection_name;
            ftpFormik.values.host = props.ftpSettings.host;
            ftpFormik.values.passive_transfer_mode = props.ftpSettings.passive_transfer_mode;
            ftpFormik.values.password = props.ftpSettings.password;
            ftpFormik.values.port = props.ftpSettings.port;
            ftpFormik.values.starting_path = props.ftpSettings.starting_path;
            ftpFormik.values.transfer_protocol = props.ftpSettings.transfer_protocol;
            ftpFormik.values.user_name = props.ftpSettings.user_name;
            setFtpProtocol(props.ftpSettings.transfer_protocol);
        }
    }, []);

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);

    const deleteConfirmed = (e) => {
        setShowDeleteConfirmationDialog(false);
        removeFtpSettings().then(() => {
            props.refreshTable();
            props.setEnableEdit(false);
            props.setEnableDelete(false);
            // props.setSelectedConnections({});
        })
    }

    const deleteDenied = () => {
        setShowDeleteConfirmationDialog(false);
    }

    return (
        <div>
            {visible && (
                <LoadOverlay active={pageLoader}>

                {showDeleteConfirmationDialog && (
                    <div className={"admin-users-delete-dialog-container"}>
                        <Dialog title={"Delete FTP Setting"} onClose={deleteDenied} className={"admin-users-delete-dialog dialog-standard"} closeIcon={false}>
                            <p className={"admin-users-delete-dialog-message-text"}>Are you sure you want to delete settings for <strong>{props.ftpSettings.connection_name}</strong>?</p>
                            <DialogActionsBar>
                                <Button className="button-standard button-white" onClick={deleteDenied}>No</Button>
                                <Button className="button-standard button-submit" onClick={deleteConfirmed}>Yes</Button>
                            </DialogActionsBar>
                        </Dialog>
                    </div>
                )}

                <Dialog title={props.createEdit === "Create" ? "New FTP Connection" : "Edit FTP Connection"} width={1000} closeIcon={false} className={"dialog-standard ftps3-dialog"}>
                    <div className={"required-fields-dialog-message"}>Fields marked with <span className="required-field">*</span> are required</div>

                    <GridLayout>
                        <GridLayoutItem row={1} col={1}>
                            <div style={{width:"100%"}}>
                                <Label
                                    className="field-label"
                                    // style={{color: ftpFormik.touched.connection_name && ftpFormik.errors.connection_name ? "red" : "rgba(0, 0, 0, 0.54)"}}
                                >
                                    Connection Name
                                    <span className="required-field">*</span>
                                </Label>
                                <Input
                                    name="connection_name"
                                    minLength={3}
                                    maxLength={64}
                                    defaultValue={props.mode==='EDIT' ? props.ftpSettings.connection_name : ''}
                                    onChange={ftpFormik.handleChange}
                                    onBlur={ftpFormik.handleBlur}
                                    // className="ftp-settings-controls"
                                    className={"text-field"}
                                    style={{borderColor: ftpFormik.touched.connection_name && ftpFormik.errors.connection_name ? "red" : ''}}>
                                </Input>
                                {ftpFormik.touched.connection_name && ftpFormik.errors.connection_name && (
                                    <span style={{color: "red"}}>{ftpFormik.errors.connection_name}</span>
                                )}
                            </div>
                        </GridLayoutItem>
                        <GridLayoutItem row={2} col={1}>
                            <div style={{width:"100%", paddingTop:"1.8rem"}}>
                                <Label
                                    className="field-label"
                                    // style={{width:"85%", color: ftpFormik.touched.host && ftpFormik.errors.host ? "red" : "rgba(0, 0, 0, 0.54)"}}
                                    style={{width:"85%"}}
                                >
                                    FTP Host Site
                                    <span className="required-field">*</span>
                                </Label>
                                <Label
                                    className="field-label"
                                    // style={{width:"15%", color: ftpFormik.touched.port && ftpFormik.errors.port ? "red" : "rgba(0, 0, 0, 0.54)"}}
                                    style={{width:"15%"}}
                                >
                                    Port Number
                                </Label>
                            </div>
                        </GridLayoutItem>
                        <GridLayoutItem row={3} col={1}>
                            <div style={{width:"100%"}}>
                                <DropDownList
                                    data={ftpProtocols}
                                    defaultValue={props.mode==='EDIT' ? props.ftpSettings.transfer_protocol : ftpProtocols[0]}
                                    style={{width: "10%"}}
                                    // className="ftp-settings-controls ftp-protocols"
                                    className={"dropdown-standard"}
                                    onChange={ftpProtocolChange}
                                    onBlur={ftpFormik.handleBlur}>
                                </DropDownList>
                                <Label style={{width: "3%", paddingLeft:".6rem"}} className="ftp-settings-controls">https://</Label>
                                <Input
                                    name="host"
                                    minLength={3}
                                    maxLength={64}
                                    defaultValue={props.mode==='EDIT' ? props.ftpSettings.host : ''}
                                    onChange={ftpFormik.handleChange}
                                    onBlur={ftpFormik.handleBlur}
                                    style={{borderColor: ftpFormik.touched.host && ftpFormik.errors.host ? "red" : ""}}
                                    // className="ftp-settings-controls"
                                    className={"text-field ftp-dialog-host-site-field"}
                                >
                                </Input>

                                <Input
                                    name="port"
                                    maxLength={5}
                                    defaultValue={props.mode==='EDIT' ? props.ftpSettings.port : ''}
                                    onChange={ftpFormik.handleChange}
                                    onBlur={ftpFormik.handleBlur}
                                    // onInput={(e) => determinePort(e, 'port')}
                                    title="If you don't know leave it blank"
                                    style={{width:"15%", borderColor: ftpFormik.touched.port && ftpFormik.errors.port ? "red" : ""}}
                                    // className="ftp-settings-controls"
                                    className={"text-field"}
                                >
                                </Input>
                                <div style={{width:"100%"}}>
                                {ftpFormik.touched.host && ftpFormik.errors.host && (
                                    <span style={{color: "red", position: "relative", left: "10.1rem", top: "-0.64rem"}}>
                                        {ftpFormik.errors.host}
                                    </span>
                                )}
                                {ftpFormik.touched.port && ftpFormik.errors.port && (
                                    <span style={{float:"right", color: "red",position: "relative", top: "-0.64rem", left: "-2rem"}}>Must be 1 - 65535</span>
                                )}
                                </div>
                            </div>
                        </GridLayoutItem>
                        <GridLayoutItem row={4} col={1}>
                            <div style={{width:"100%", paddingTop:"1.8rem"}}>
                                <Label className="field-label" style={{width:"85%"}}>Destination Directory</Label>
                                <Label className="field-label" style={{width:"15%"}}>Transfer Mode</Label>
                            </div>
                        </GridLayoutItem>
                        <GridLayoutItem row={5} col={1}>
                            <div style={{width:"100%"}}>
                                <Input
                                    name="starting_path"
                                    minLength={3}
                                    maxLength={64}
                                    defaultValue={props.mode==='EDIT' ? props.ftpSettings.starting_path : ''}
                                    style={{width:"82%", marginRight:"2.9%"}}
                                    // className="ftp-settings-controls"
                                    className={"text-field"}
                                    onChange={ftpFormik.handleChange}
                                    onBlur={ftpFormik.handleBlur}>
                                </Input>
                                <DropDownList
                                    name="passive_transfer_mode"
                                    data={transferModes}
                                    defaultValue={determineTransferMode()}
                                    dataItemKey="id"
                                    textField="text"
                                    style={{width: "15%"}}
                                    // className="ftp-settings-controls transfer-mode"
                                    className={"dropdown-standard"}
                                    title="Try changing this if you can't connect"
                                    onChange={ftpFormik.handleChange}
                                    onBlur={ftpFormik.handleBlur}>
                                </DropDownList>
                            </div>
                        </GridLayoutItem>
                        <GridLayoutItem row={6} col={1}>
                            <div style={{width:"100%", paddingTop:"1.8rem"}}>
                                <Label
                                    className="field-label"
                                    // style={{width:"71%", color: ftpFormik.touched.user_name && ftpFormik.errors.user_name ? "red" : "rgba(0, 0, 0, 0.54)"}}
                                    style={{width:"71%"}}
                                >
                                    User Name
                                    <span className="required-field">*</span>
                                </Label>
                                <Input
                                    name="user_name"
                                    maxLength={64}
                                    defaultValue={props.mode==='EDIT' ? props.ftpSettings.user_name : ''}
                                    onChange={ftpFormik.handleChange}
                                    onBlur={ftpFormik.handleBlur}
                                    style={{width:"82%", marginRight:"2.9%", borderColor: ftpFormik.touched.user_name && ftpFormik.errors.user_name ? "red" : ""}}
                                    // className="ftp-settings-controls"
                                    className={"text-field"}
                                >
                                </Input>
                                <div>
                                    {ftpFormik.touched.user_name && ftpFormik.errors.user_name && (
                                        <span style={{color: "red"}}>{ftpFormik.errors.user_name}</span>
                                    )}
                                </div>
                            </div>
                        </GridLayoutItem>
                        <GridLayoutItem row={7} col={1}>
                            <div style={{width:"100%", paddingTop:"1.8rem"}}>
                                <Label
                                    className="field-label"
                                    style={{width:"71%"}}>Password
                                    <span className="required-field">*</span>
                                </Label>
                                <Input
                                    name="password"
                                    type="password"
                                    maxLength={64}
                                    defaultValue={props.mode==='EDIT' ? props.ftpSettings.password : ''}
                                    onChange={ftpFormik.handleChange}
                                    onBlur={ftpFormik.handleBlur}
                                    style={{width:"82%", marginRight:"2.9%", borderColor: ftpFormik.touched.password && ftpFormik.errors.password ? "red" : ""}}
                                    // className="ftp-settings-controls"
                                    className={"text-field"}
                                >
                                </Input>
                                <div>
                                    {ftpFormik.touched.password && ftpFormik.errors.password && (
                                        <span style={{color: "red"}}>{ftpFormik.errors.password}</span>
                                    )}
                                </div>
                            </div>
                        </GridLayoutItem>
                    </GridLayout>
                    <DialogActionsBar
                        // layout={"end"}
                    >

                        <div className={"dialog-buttons-container"}>
                            <div className={"dialog-buttons-left-div"}>
                                {props.mode === 'EDIT' && (<Button
                                    // fillMode="link"
                                    // className="delete-settings"
                                    className={"button-standard button-red dialog-button-delete"}
                                    onClick={ async () => {
                                        setShowDeleteConfirmationDialog(true);
                                    }}>
                                    Delete
                                </Button>)}
                                <Button
                                    className="button-standard button-white test-con-button"
                                    onClick={async () => {
                                        await ftpFormik.submitForm();
                                        const errors = await ftpFormik.validateForm();
                                        if (!Object.keys(errors).length) {
                                            await testFTPSettings();
                                        }
                                    }}>
                                    Test Connection
                                </Button>
                            </div>

                            <div className={"dialog-buttons-right-div"}>
                                <Button
                                    // className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary dialog-cancel"
                                    className={"button-standard button-white"}
                                    onClick={() => {
                                        props.cancelFTPSettings();
                                    }}>
                                    Cancel
                                </Button>
                                <Button
                                    className={"button-standard button-submit"}

                                    disabled={disableSubmit}
                                    onClick={ async () => {
                                        await setDisableSubmit(true);
                                        await ftpFormik.submitForm();
                                        const errors = await ftpFormik.validateForm();

                                        if (!Object.keys(errors).length) {
                                            const hostName = getHostName(ftpFormik.values.host);
                                            const payload = {...ftpFormik.values, host: hostName, transfer_protocol: ftpProtocol};
                                            setPageLoader(true);
                                            if (props.mode ==='EDIT') {
                                                await testExistingConnection(payload,FTP_STRING,true).then(async (res) => {
                                                    setPageLoader(false);
                                                    if(res) {
                                                        await saveFTPSettings()
                                                            .then(async () => {
                                                                props.refreshTable();
                                                            })
                                                    }
                                                });
                                            } else {
                                                await testNewConnection(payload,FTP_STRING,true).then(async (res) => {
                                                    setPageLoader(false);
                                                    if(res) {
                                                        await saveFTPSettings()
                                                            .then(async () => {
                                                                props.refreshTable();
                                                            })
                                                    }
                                                });
                                            }
                                        }
                                        setDisableSubmit(false);
                                    }}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
                </LoadOverlay>
            )}
        </div>
    )
}

export default FTPSettings;